.grass {
  width: 260px;
}

.grass img {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border: 1px solid #dfe1e5;
  border-radius: 20px;
  margin-top: 5px;
}
.grass.selected_grass img,
.grass:hover img {
  border: 3px solid var(--secondary-color);
}

.grass_price {
  background-color: var(--primary-color);
  font-weight: 600;
  border-radius: 20px;
  color: #ffffff;
}

.grass.selected_grass .grass_price,
.grass:hover .grass_price {
  background-color: var(--secondary-color);
}

@media screen and (max-width: 768px) {
  .grass {
    width: 180px;
  }
  .grass img {
    height: 150px;
  }
}

@media screen and (max-width: 400px) {
  .grass {
    width: 150px;
  }
}
