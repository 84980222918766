.thank-you-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  /* background-color: #f5f5f5; */
  background-color: #f4f8fa;
}

.thank-you-page {
  display: flex;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 66px;
  margin-bottom: 60px;
}

.thank-you-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  border: 1px solid var(--text-color-level3, rgba(26, 26, 26, 0.1));
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgb(0 0 0 / 8%);
  background-color: var(--color-bg-white-l-1, rgba(255, 255, 255, 1));
  padding: 20px 100px;
}

.thank-you-page-header-status {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgba(79, 191, 135, 0.1);
}

.lazy-lawn-thank-you-content p {
  padding-bottom: 1em;
  font-family: 'Montserrat', sans-serif;
  color: #666;
  line-height: 1.5rem;
}
.calender-item {
  color: var(--text-color-level2, rgba(26, 26, 26, 0.61));
  padding: 6px 0;
}

@media screen and (max-width: 768px) {
  .thank-you-page-content {
    width: 90%;
    padding: 20px 20px;
  }
}
