.input-field-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 3px;
  margin-bottom: 10px;
}
.input-field-wrapper__item {
  padding: 5px;
  width: 50%;
}
.input-field-wrapper__item-25 {
  padding: 5px;
  width: 25%;
}
.input-field-wrapper__item-estimate-area {
  padding: 5px;
}
.diy-form {
  box-shadow: 0 4px 8px 0 rgba(53, 105, 128, 0.3), 0 6px 20px 0 rgba(165, 200, 213, 0.41);
}

@media screen and (max-width: 768px) {
  .input-field-wrapper {
    flex-direction: column;
  }

  .input-field-wrapper__item-25,
  .input-field-wrapper__item {
    width: 100%;
  }
}
