@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
}

/**** REST DEFAULT STYLES ****/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  font-family: 'Cabin Sketch', Helvetica, Arial, Lucida, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

:root {
  --primary-color: #1f8404;
  --secondary-color: #f05223;
  --tertiary-color: #424242;
  --border-color: #ccd1d9;

  --lazy-sizes-full: 100%;
  --lazy-sizes-3xs: 14rem;
  --lazy-sizes-2xs: 16rem;
  --lazy-sizes-xs: 20rem;
  --lazy-sizes-sm: 24rem;
  --lazy-sizes-md: 28rem;
  --lazy-sizes-lg: 32rem;
  --lazy-sizes-xl: 36rem;
  --lazy-sizes-2xl: 42rem;
  --lazy-sizes-3xl: 48rem;
  --lazy-sizes-4xl: 56rem;
  --lazy-sizes-5xl: 64rem;
  --lazy-sizes-6xl: 72rem;
  --lazy-sizes-7xl: 80rem;
  --lazy-sizes-8xl: 90rem;

  --lazy-transition-property-common: background-color, border-color, color, fill, stroke, opacity,
    box-shadow, transform;
  --lazy-transition-property-colors: background-color, border-color, color, fill, stroke;
  --lazy-transition-property-dimensions: width, height;
  --lazy-transition-property-position: left, right, top, bottom;
  --lazy-transition-property-background: background-color, background-image, background-position;
  --lazy-transition-easing-ease-in: cubic-bezier(0.4, 0, 1, 1);
  --lazy-transition-easing-ease-out: cubic-bezier(0, 0, 0.2, 1);
  --lazy-transition-easing-ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);

  --lazy-transition-duration-75: 75ms;
}

.home-banner {
  background-color: #ffffff;
}

.notfound-banner {
  background-color: var(--primary-color);
  background-image: url('/public/assets/banner.webp') !important;
  background-size: cover;
}

.title {
  display: inline-block;
  will-change: transform;
  overflow: hidden;
  margin-bottom: 0.5rem;
  font-size: 3.8rem;
}

.map-area {
  text-align: center;
  display: none;
  position: relative;
  z-index: 0;
  width: 100%;
  max-width: 800px;
  margin: 0px auto;
}

.invalid-field {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* SCROLL BAR */

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  background-color: #424242 !important;
  border-radius: 10px !important;
  background-image: linear-gradient(180deg, #ff4f00 0%, #1f8404 99%) !important;
  border-radius: 100px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
