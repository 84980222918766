.lazy-estimate-inner-container {
  width: 780px;
  transform-origin: top;
}

.lazy-estimate-template {
  padding: 50px;
  background: #fff;
  font-size: 14px;
  line-height: 18px;
}
.invoice,
.invoice * {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  transition-property: none;
}
.invoice {
  box-shadow: 2px 2px 0 rgb(0 27 64 / 10%);
  margin-bottom: 24px;
  position: relative;
  width: 780px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  color: #000;
}

.invoice-contentRow {
  display: table-row;
}

.lazy-estimate-template .invoice-contentCell {
  height: 1px;
  padding-bottom: 20px;
  vertical-align: top;
}

.invoice-contentCell {
  display: table-cell;
}

.lazy-estimate-template .invoice-metaData {
  display: table;
  width: 100%;
}
.lazy-estimate-template .invoice-metaDataRow {
  display: table-row;
}
.lazy-estimate-template .invoice-metaDataCell {
  display: table-cell;
  vertical-align: top;
}
.lazy-estimate-template .invoice-metaDataCell {
  display: table-cell;
  vertical-align: top;
}

.lazy-estimate-template .invoice-metaDataSummary {
  width: 60%;
}
.lazy-estimate-template .invoice-metaDataSummary .invoice-labelValuePair {
  display: table;
  width: 100%;
}

.lazy-estimate-template .invoice-labelValuePair {
  margin-bottom: 8px;
}
.invoice-labelValuePair--dateField {
  position: relative;
}
.lazy-estimate-template .invoice-metaDataSummary .invoice-label {
  width: 125px;
}
.lazy-estimate-template .invoice-metaDataSummary .invoice-label,
.lazy-estimate-template .invoice-metaDataSummary .invoice-value {
  display: table-cell;
}
.lazy-estimate-template .invoice-label {
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.lazy-estimate-template .invoice-metaDataSummary .invoice-value {
  text-align: right;
}

.lazy-estimate-template .invoice-metaDataSummary .invoice-label,
.lazy-estimate-template .invoice-metaDataSummary .invoice-value {
  display: table-cell;
}
.lazy-estimate-template .invoice-contentRow--last .invoice-contentCell {
  padding-bottom: 0;
  height: 100%;
}
.lazy-estimate-template .invoice-lineItems--summary {
  width: 50%;
  margin-left: auto;
  margin-top: 25px;
  padding-bottom: 20px;
}

.invoice-lineItems {
  display: table;
  width: 100%;
}
.invoice-lineItemCell {
  position: relative;
  display: table-cell;
  text-align: right;
  white-space: nowrap;
  vertical-align: top;
}
.invoice-themeColoredBorder {
  border-color: var(--secondary-color) !important;
}
.invoice-themeColoredBorder {
  border-color: var(--secondary-color) !important;
}
.invoice-lineItem--header {
  white-space: nowrap;
}
.lazy-estimate-template .invoice-lineItem--header .invoice-lineItemCell {
  padding-bottom: 10px;
  border-bottom: none;
  border-top-width: 3px;
  border-top-style: solid;
  border-top-color: inherit;
}
.lazy-estimate-template .invoice-lineItemCell--nonNumeric {
  width: 100%;
  padding-left: 0;
}
.lazy-estimate-template .invoice-lineItemCell {
  min-width: 100px;
  padding: 10px 0 10px 20px;
  border-bottom: 1px solid var(--border-color);
}
.line-items-desc {
  padding-left: 2px !important;
  text-align: left;
  white-space: normal;
}

.invoice-header-title {
  color: var(--secondary-color) !important;
}
.invoice-lineItemCell--nonNumeric {
  text-align: left;
  white-space: normal;
}
.lazy-estimate-template .invoice-lineItemSecondaryData {
  font-size: 12px;
  line-height: 16px;
  vertical-align: bottom;
}
.lazy-estimate-template .invoice-lineItems--summary {
  width: 50%;
  margin-left: auto;
  margin-top: 25px;
  padding-bottom: 20px;
}
.lazy-estimate-template .invoice-lineItems--summary .invoice-lineItemCell {
  border-bottom: none;
}
.u-paddingBottom--quarter {
  padding-bottom: 6px;
}
.invoice-lineItem {
  display: table-row;
}
.lazy-estimate-template .invoice-lineItem--amountDue .invoice-lineItemCell,
.lazy-estimate-template .invoice-lineItem--total .invoice-lineItemCell {
  border-top: 1px solid var(--border-color);
}
.lazy-estimate-template .invoice-lineItems--summary .invoice-lineItemCell {
  border-bottom: none;
}

.lazy-estimate-template .invoice-lineItem--amountDue .invoice-lineItemCell:before,
.mobileTemplate .invoice-lineItem--amountDue .invoice-lineItemCell:before {
  content: '';
  position: absolute;
  top: -3px;
  left: 0;
  right: 0;
  border-top: 1px solid var(--border-color);
}
.estimate-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.estimate-header-estimate-metadata {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.estimate-header-estimate-metadata-estimate-number,
.estimate-header-estimate-metadata-estimate-date {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px;
  white-space: nowrap;
}
@media only screen and (min-width: 1310px) {
  .estimate-container {
    width: 1080px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 10px;
    padding-left: 10px;
    transition-property: none;
  }
}

/* ESTIMATE ACTIONS */
.estimate-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  margin: 0 auto;
  width: 780px;
}

/* ESTIMATE ACTIONS */
.estimate-feedback {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 0 auto;
  width: 780px;
}

@media screen and (max-width: 768px) {
  .invoice {
    margin-bottom: 10px;
    width: 100%;
  }
  .lazy-estimate-inner-container {
    width: 100%;
  }
  .lazy-estimate-template {
    padding: 8px;
  }
  .lazy-estimate-template .invoice-lineItems--summary {
    width: 100%;
    margin: 10px 0;
  }
  .lazy-estimate-template .invoice-lineItemCell {
    min-width: 70px;
    padding: 5px 0 5px 0px;
  }
  .estimate-feedback,
  .estimate-actions {
    flex-direction: column-reverse;
    width: 100%;
    text-align: center;
  }
  .estimate-actions-new,
  .calendly-btn {
    margin: 10px 0;
  }
}
